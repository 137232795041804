.map-container {
    height: 100vh;
    width: 100vw;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    bottom: 8vh;
    left: 0;
    margin: 12px 24px;
    border-radius: 4px;
}

@media (max-width: 844px) {
    .sidebar {
        background-color: rgba(35, 55, 75, 0.9);
        color: #fff;
        padding: 6px 12px;
        font-family: monospace;
        z-index: 1;
        margin: 0;
        left: 50%;
        bottom: 20px;
        transform: translate(-50%, 0%);
        border-radius: 4px;
        width: 285px;
        font-size: 10px;
        text-align: center;
    }
}
.mapboxgl-popup-content {
    background-color: white;
    padding: 4px;
    font-size: 12px;
}

.popup {
    background-color: white;
    color: black;
    font-family: var(--font-body);
    font-weight: 800;
    padding: 2px 10px;
    border-radius: 2px;
}

.popup:hover{
    cursor: default;
}

.marker {
    background-image: url(./assets/marker.png);
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}